import React from 'react';
import { Box, Card, Chip, Typography, useTheme } from '@mui/material';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { RegisteredUser, SubscribedUser } from 'types/apiResponses';
import userUtils from 'utils/userUtils';

interface CardProps {
  icon: React.ReactElement;
  title: string;
  data: RegisteredUser[] | SubscribedUser[];
  selectedPeriod: string
}

const DashboardCard: React.FC<CardProps> = ({ icon, title, data, selectedPeriod }) => {
  const theme = useTheme();

  const percentage = userUtils.calculateUserGrowth(
    data,
    selectedPeriod
  );

  return (
    <Card sx={{ 
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      textAlign: 'center',
      p: 2,
      m: 1,
      boxShadow: theme.shadows[3],
      borderRadius: 2,
    }}>
        <Box sx={{ m: 2 }}>
          {icon}
        </Box>
        <Typography variant="h5" sx={{ fontSize: "3.5rem", fontWeight: 700, mb: 1, color: theme.palette.primary.main }}>
          {percentage.count}
        </Typography>
        <Typography color="text.secondary" gutterBottom>
          {title}
        </Typography>
        <Box>
        {/*<Chip
          icon={positive ? <ExpandLessIcon color='success' /> : <KeyboardArrowDownIcon color='error' />}
          label={`${percentage} %`}
          sx={{
            fontWeight: 'bold',
            backgroundColor: positive ? theme.palette.success.contrastText : theme.palette.danger,
            color: positive ? theme.palette.success.main : theme.palette.dangerText,
            fontSize: '0.875rem',
            mb: 2,
          }}
        />*/}
        </Box>
    </Card>
  );
};

export default DashboardCard;
