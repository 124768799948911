// Helper function to format a date to ISO without time
const formatDateToISO = (date: Date) => date.toISOString().split("T")[0];

// Helper functions to get start and end dates for different periods
const getStartEndDateForThisWeek = () => {
  const today = new Date();
  const firstDayOfWeek = today.getDate() - today.getDay(); // First day is the day of the month - the day of the week
  const lastDayOfWeek = firstDayOfWeek + 6; // last day is the first day + 6

  const startDate = new Date(today.setDate(firstDayOfWeek));
  const endDate = new Date(today.setDate(lastDayOfWeek));

  return [startDate.toISOString().split('T')[0], endDate.toISOString().split('T')[0]];
};

const getStartEndDateForPreviousWeek = () => {
  const endDate = new Date();
  endDate.setDate(endDate.getDate() - endDate.getDay() - 1);
  const startDate = new Date(endDate);
  startDate.setDate(endDate.getDate() - 6);
  return [formatDateToISO(startDate), formatDateToISO(endDate)];
};

const getStartEndDateForThisMonth = () => {
  const startDate = new Date();
  startDate.setDate(1);
  const endDate = new Date(
    startDate.getFullYear(),
    startDate.getMonth() + 1,
    0
  );
  return [formatDateToISO(startDate), formatDateToISO(endDate)];
};

const getStartEndDateForPreviousMonth = () => {
  const endDate = new Date();
  endDate.setDate(0); // Last day of the previous month
  const startDate = new Date(endDate.getFullYear(), endDate.getMonth(), 1);
  return [formatDateToISO(startDate), formatDateToISO(endDate)];
};

const parseISODate = (dateString: string): Date => {
  return new Date(dateString + 'Z'); // Append 'Z' to force UTC parsing
}

// Assign the export object to a variable
const dateUtils = {
  formatDateToISO,
  getStartEndDateForThisWeek,
  getStartEndDateForPreviousWeek,
  getStartEndDateForThisMonth,
  getStartEndDateForPreviousMonth,
  parseISODate,
};

export default dateUtils;