import { RegisteredUser, SubscribedUser } from "types/apiResponses";
import dateUtils from "../utils/dateUtils";

// Function to filter users based on a date range
const filterUsersByDateRange = (
  users: RegisteredUser[],
  startDate: string | number,
  endDate: string | number
) => {
  return users.filter(user => {
    const userCreatedAt = new Date(user.createdAt);
    const start = new Date(startDate);
    const end = new Date(endDate);
    end.setHours(23, 59, 59, 999); // Set to the end of the day
    return userCreatedAt >= start && userCreatedAt <= end;
  });
};

export const periods = {
  allTime: "All Time",
  thisWeek: "This Week",
  previousWeek: "Previous Week",
  thisMonth: "This Month",
  previousMonth: "Previous Month",
}; 

type User = RegisteredUser | SubscribedUser;

export function calculateUserGrowth(users: User[], period: string): { growth: string, count: number } {
  let startDate: Date;
  let endDate: Date;
  let previousStartDate: Date;
  let previousEndDate: Date;

  switch (period) {
    case periods.allTime:
      // Directly use the earliest and latest dates from user data
      startDate = new Date(Math.min(...users.map(user => new Date('createdAt' in user ? user.createdAt : user.subscriptionCreated).getTime())));
      endDate = new Date(Math.max(...users.map(user => new Date('createdAt' in user ? user.createdAt : user.subscriptionCreated).getTime())));
      return {
        count: users.length,
        growth: "N/A"
      };
    case periods.thisWeek:
      [startDate, endDate] = [new Date(dateUtils.getStartEndDateForThisWeek()[0]), new Date(dateUtils.getStartEndDateForThisWeek()[1])];
      break;
    case periods.previousWeek:
      [startDate, endDate] = [new Date(dateUtils.getStartEndDateForPreviousWeek()[0]), new Date(dateUtils.getStartEndDateForPreviousWeek()[1])];
      break;
    case periods.thisMonth:
      [startDate, endDate] = [new Date(dateUtils.getStartEndDateForThisMonth()[0]), new Date(dateUtils.getStartEndDateForThisMonth()[1])];
      break;
    case periods.previousMonth:
      [startDate, endDate] = [new Date(dateUtils.getStartEndDateForPreviousMonth()[0]), new Date(dateUtils.getStartEndDateForPreviousMonth()[1])];
      break;
    default:
      throw new Error(`Unsupported period: ${period}`);
  }

  previousStartDate = new Date(startDate);
  previousStartDate.setDate(startDate.getDate() - (endDate.getDate() - startDate.getDate()));
  previousEndDate = new Date(startDate);

  const currentPeriodUsers = users.filter(user => {
    const createdAt = new Date('createdAt' in user ? user.createdAt : user.subscriptionCreated);
    return createdAt >= startDate && createdAt < endDate;
  }).length;

  const previousPeriodUsers = users.filter(user => {
    const createdAt = new Date('createdAt' in user ? user.createdAt : user.subscriptionCreated);
    return createdAt >= previousStartDate && createdAt < previousEndDate;
  }).length;

  const growth = previousPeriodUsers === 0 ? "N/A" : (((currentPeriodUsers - previousPeriodUsers) / previousPeriodUsers) * 100).toFixed(2) + "%";

  return {
    count: currentPeriodUsers,
    growth
  };
}


export const access = {
  noSubscription: "no subscription",
  vip: "VIP",
};

// Main function to transform user data based on the selected period
const transformUserData = (users: RegisteredUser[], period: string) => {
  let startDate, endDate;
  switch (period) {
    case periods.thisWeek:
      [startDate, endDate] = dateUtils.getStartEndDateForThisWeek();
      break;
    case periods.previousWeek:
      [startDate, endDate] = dateUtils.getStartEndDateForPreviousWeek();
      break;
    case periods.thisMonth:
      [startDate, endDate] = dateUtils.getStartEndDateForThisMonth();
      break;
    case periods.previousMonth:
      [startDate, endDate] = dateUtils.getStartEndDateForPreviousMonth();
      break;
    case periods.allTime:
      // For "All Time", we don't need a start and end date
      startDate = null;
      endDate = null;
      break;
    default:
      throw new Error(`Unsupported period: ${period}`);
  }
  // Filter users based on the calculated date range
  const filteredUsers =
    startDate && endDate
      ? filterUsersByDateRange(users, startDate, endDate)
      : users;

  // Group users by date and count them
  const countByDate: Record<string, number> = {};

  filteredUsers.forEach((user) => {
    const date = dateUtils.formatDateToISO(new Date(user.createdAt));
    countByDate[date] = (countByDate[date] || 0) + 1;
  });

  // Convert the counts to chart data
  return Object.entries(countByDate).map(([date, count]) => ({
    argument: date,
    value: count,
  }));
};

const userUtils = {
  filterUsersByDateRange,
  transformUserData,
  calculateUserGrowth
};

export default userUtils;
