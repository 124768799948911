import axios, { AxiosError } from 'axios';
import { ErrorResponse } from '../types/apiResponses';

// Create an Axios instance with default properties
const axiosInstance = axios.create({
  baseURL: 'https://admin.elparlay.com/api', 
  timeout: 10000, // Define a timeout for all requests
  headers: {
    'Content-Type': 'application/json', // Default Content-Type header
    // Add any other headers you need to include with every request
  },
});

// Request interceptor for API calls
axiosInstance.interceptors.request.use(
  async (config) => {
    const userInfo = localStorage.getItem('userInfo');
    if (userInfo) {
      const { token } = JSON.parse(userInfo);
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
    }
    // You can modify or log the config here if needed
    return config;
  },
  (error) => {
    // Do something with request error here
    return Promise.reject(error);
  },
);

// Response interceptor for API calls
axiosInstance.interceptors.response.use(
  response => response,
  (error: AxiosError<ErrorResponse>) => { // Now using the ErrorResponse interface
    let errorMessage = 'An unknown error occurred';
    if (error.response) {
      errorMessage = error.response.data.message || 'Error occurred';
    } else if (error.request) {
      errorMessage = 'No response received from the server';
    } else {
      errorMessage = error.message;
    }
    return Promise.reject(new Error(errorMessage));
  }
);

export default axiosInstance;
