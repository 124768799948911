import React from 'react';
import { Dropdown as MuiDropdown } from "@mui/base/Dropdown";
import { Listbox, MenuButton, MenuItem } from "../theme/components";
import { Menu } from "@mui/base/Menu";
import { periods } from '../utils/userUtils';

// Define the types for your component props
interface PeriodDropdownProps {
  selectedPeriod: string;
  onSelectPeriod: (period: string) => void;
}

export const PeriodDropdown: React.FC<PeriodDropdownProps> = ({ selectedPeriod, onSelectPeriod }) => {
  const handleMenuClick = (period: string) => {
    onSelectPeriod(period);
  };

  return (
    <MuiDropdown>
      <MenuButton>{selectedPeriod}</MenuButton>
      <Menu slots={{ listbox: Listbox }}>
        {Object.entries(periods).map(([key, period]) => (
          <MenuItem
            key={key}
            value={key}
            onClick={() => handleMenuClick(period)}
          >
            {period}
          </MenuItem>
        ))}
      </Menu>
    </MuiDropdown>
  );
};
